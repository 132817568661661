import React, { Fragment, PureComponent } from 'react'
import { Layout, Jumbotron, Marks } from '../../../components'
import '../../style.less'

class ChildrensMenu extends PureComponent {
  componentDidMount () {
    document.body.scrollTop = 0
  }

  render () {
    return (
      <Fragment>
        <Layout
          title={"Children's Menu"}
          location={this.props.location}
        >
          <Jumbotron
            heading={'Childrens Menu\'s'}
            image={'childrens.jpg'}
          />
          <div className='container'>
            <Marks />
            <div className='row'>
              <div className='col-md-offset-4 col-xs-offset-1'>
                <h3 >Main Meal &amp; Dessert for £9.95</h3>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div >
                  <h3 className='para-h2'>Choose your Main</h3>
                  <div className='row'>
                    <div className='col-md-6 col-xs-12'>
                      <p>Tomato Penne Pasta <span className='vegetarian-mark'>V</span> <i style={{ color: '#ff7700' }}>No Additional Side</i></p>
                      <p>Ham, Egg &amp; Chips <span className='gf-mark'>GF</span> <i style={{ color: '#ff7700' }}>No Additional Side</i></p>
                      <p>Fish Fingers <span className='gf-mark'>GF</span></p>
                      <p>Farmhouse Sausages </p>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                      <p>Margarita Pizza <span className='vegetarian-mark'>V</span></p>
                      <p>Crispy Chicken chunks </p>
                      <p>Beef Burger <i>Add Cheese £0.75, or Bacon £1.25</i></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 col-xs-12'>
                <div >
                  <h3 className='para-h2' >Choose your Potato</h3>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <p>Chips <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span></p>
                      <p>Mashed Potatoes <span className='gf-mark'>GF</span></p>
                      <p>Garlic Bread</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 col-xs-12'>
                <div >
                  <h3 className='para-h2'>Choose your Side</h3>
                  <div className='row'>
                    <div className='col-md-6 col-xs-12'>
                      <p>Garden Peas <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span></p>
                      <p>Baked Beans <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span></p>
                      <p>Sweetcorn <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span></p>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                      <p>Cherry Tomatoes &amp; Cucumber <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span></p>
                      <p>Salad <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 col-xs-12'>
                <div >
                  <h3 className='para-h2'>Choose your Dessert</h3>
                  <p>Strawberry, Chocolate, or Vanilla Ice Cream with Either Chocolate or Strawberry Sauce <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span></p>
                  <p>Mini Sugared Doughnuts With Chocolate Sauce <span className='vegetarian-mark'>V</span></p>
                  <p>Strawberry Jelly pot with Vanilla Ice Cream <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span></p>
                  <p>Fresh Fruit <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span></p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div>
                  <h3 className='para-h2'>Little Extra&apos;s</h3>
                  <div className='row'>
                    <div className='col-md-10 col-xs-10'>
                      <div>Children&apos;s Soup - Served with Roll <span className='vegetarian-mark'>V</span></div>
                    </div>
                    <div className='col-md-2 col-xs-2'>
                      <div>£3.25</div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-10 col-xs-10'>
                      <div>Garlic Bread <span className='vegetarian-mark'>V</span></div>
                    </div>
                    <div className='col-md-2 col-xs-2'>
                      <div>£2.95</div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-10 col-xs-10'>
                      <div>Cheesy Garlic Bread <span className='vegetarian-mark'>V</span></div>
                    </div>
                    <div className='col-md-2 col-xs-2'>
                      <div>£3.95</div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-10 col-xs-10'>
                      <div>Beer Battered Onion Rings <span className='vegetarian-mark'>V</span></div>
                    </div>
                    <div className='col-md-2 col-xs-2'>
                      <div>£1.95</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 col-xs-12'>
                <div>
                  <h3 className='para-h2'>Children&apos;s Drinks</h3>
                  <div className='para-h4'>
                    <h4>Flavoured Milk <p className='pull-right'>£2.00</p></h4>
                    <p>Choose from Chocolate, Strawberry or Banana</p>
                  </div>
                  <div className='para-h4'>
                    <h4>Glass of Semi Skimmed Milk <p className='pull-right'>£1.50</p></h4>
                  </div>
                  <div className='para-h4'>
                    <h4>Fruit Shoot <p className='pull-right'>£2.00</p></h4>
                    <p>Choose from Orange or Blackcurrant</p>
                  </div>
                  <div className='para-h4'>
                    <h4>1/2 Pint of Post Mix <p className='pull-right'>£2.20</p></h4>
                    <p>Choose from Coke, Diet Coke, Coke Zero, or Lemonade</p>
                  </div>
                  <div className='para-h4'>
                    <h4>Squash <p className='pull-right'>£1.00</p></h4>
                    <p>Choose from Orange or Blackcurrant</p>
                  </div>
                  <div className='para-h4'>
                    <h4>J2O <p className='pull-right'>£3.00</p></h4>
                    <p>Choose from Apple &amp; Mango, Apple &amp; Raspberry, or Orange &amp; Passion Fruit</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </Fragment>
    )
  }
}

export default ChildrensMenu
